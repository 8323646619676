import * as Sentry from "@sentry/nextjs";
import { logoutUser } from "../api/auth";

export const fetcher = async (
  url: string,
  data: RequestInit = {},
  retJson = true
): Promise<unknown> => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/${url}`,
      data
    );

    if (!response.ok) {
      throw await response.json();
    }

    if (!response.url.includes("auth") && response.status === 401) {
      logoutUser();
      window.location.reload();
      return;
    }

    return retJson ? response.json() : response;
  } catch (error) {
    const errorObject = error as Error;

    Sentry.captureException({
      category: "fetcher",
      message: "Error in fetcher",
      data: errorObject,
    });

    console.error("Error:", error);

    throw error;
  }
};
